import HttpRepository from './httpRepository'
import { subscribeEmailNotificationsCommon, TermsVersion } from '~/types/api'
import { TERMS_OF_SERVICE } from '~/helper/enum'
export default class UserRepository extends HttpRepository {
  async updateEmail({ email }: { email: string }): Promise<any> {
    const data = await this.patch('/api/users/email', {
      email
    })
    return data
  }

  async unsubscribeUserMail({ mailType }: { mailType: string }): Promise<any> {
    const data = await this.post('/api/users/unsubscribeUserMail', {
      mailType
    })
    return data
  }

  async getSubscribedEmailNotifications(): Promise<subscribeEmailNotificationsCommon> {
    const data = await this.get(`/api/users/getSubscribedEmailNotifications`)
    return data
  }

  async updateSubscribedEmailNotifications(
    emailSettings: subscribeEmailNotificationsCommon
  ): Promise<any> {
    const data = await this.patch(
      '/api/users/updateSubscribedEmailNotifications',
      {
        ...emailSettings
      }
    )
    return data
  }

  async updateAgreedTermsOfServiceVersion(version: string): Promise<string> {
    const data = await this.patch(
      `/api/users/updateAgreedTermsOfServiceVersion`,
      {
        version
      }
    )
    return data
  }

  async getLatestTermsOfServiceVersion(): Promise<TermsVersion> {
    const data = await this.post(`/api/users/getLatestTermsOfServiceVersion`, {
      term: TERMS_OF_SERVICE
    })
    return data
  }

  async getUnreadCounts(): Promise<number> {
    const data = await this.get(`/api/users/getUnreadCounts`)
    return data
  }
}
