import HttpRepository from './httpRepository'
import { DocsiruCoMedicalOccupation } from '~/types/api'

export default class DoctorRepository extends HttpRepository {
  async fetchDoctorDetail(doctorId: string): Promise<any> {
    const data = await this.get(`/api/doctor/get?id=${doctorId}`)

    return data
  }

  registerCreateCoMedical(params: {
    lastnameJa: string
    firstnameJa: string
    gender: number
    password: string
    email: string
    isTemporary: boolean
    hospitalId: string
    department: string
    occupation: DocsiruCoMedicalOccupation
  }): Promise<void> {
    return this.post('/api/doctor/registerCreateCoMedical', params)
  }
}
